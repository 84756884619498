label {
  cursor: pointer;
  /* Style as you please, it will become the visible UI component. */
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.progressbar-progress {
  height: 100% !important;
  border-radius: 0.5rem 0 0 0.5rem;
  background-color: #000 !important;
}
.edit-video {
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: "298px";
  height: "235px";
  display: none;
}
.videoWrapper:hover .edit-video {
  border-radius: 1rem;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  display: block;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.bg-blur {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}
