@font-face {
  font-family: 'AvenirLTStd-BlackOblique';
  src: url('../assets/fonts/Font-Files/AvenirLTStd-BlackOblique.woff2') format('woff2'),
      url('../assets/fonts/Font-Files/AvenirLTStd-BlackOblique.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirLTStd-Black';
  src: url('../assets/fonts/Font-Files/AvenirLTStd-Black.woff2') format('woff2'),
      url('../assets/fonts/Font-Files/AvenirLTStd-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont-Black';
  src: url('../assets/fonts/Font-Files/Mont-Black.woff2') format('woff2'),
      url('../assets/fonts/Font-Files/Mont-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont-BlackItalic';
  src: url('../assets/fonts/Font-Files/Mont-BlackItalic.woff2') format('woff2'),
      url('../assets/fonts/Font-Files/Mont-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'FuturaStd-BoldOblique';
  src: url('../assets/fonts/Font-Files/FuturaStd-BoldOblique.woff2') format('woff2'),
      url('../assets/fonts/Font-Files/FuturaStd-BoldOblique.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'FuturaStd-Bold';
  src: url('../assets/fonts/Font-Files/FuturaStd-Bold.woff2') format('woff2'),
      url('../assets/fonts/Font-Files/FuturaStd-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaLTStd-BlkCondObl';
  src: url('../assets/fonts/Font-Files/HelveticaLTStd-BlkCondObl.woff2') format('woff2'),
      url('../assets/fonts/Font-Files/HelveticaLTStd-BlkCondObl.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaLTStd-BlkCond';
  src: url('../assets/fonts/Font-Files/HelveticaLTStd-BlkCond.woff2') format('woff2'),
      url('../assets/fonts/Font-Files/HelveticaLTStd-BlkCond.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-BdCnO';
  src: url('../assets/fonts/Font-Files/HelveticaNeueLTStd-BdCnO.woff2') format('woff2'),
      url('../assets/fonts/Font-Files/HelveticaNeueLTStd-BdCnO.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-BdCn';
  src: url('../assets/fonts/Font-Files/HelveticaNeueLTStd-BdCn.woff2') format('woff2'),
      url('../assets/fonts/Font-Files/HelveticaNeueLTStd-BdCn.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LucidaSansStd-Bold';
  src: url('../assets/fonts/Font-Files/LucidaSansStd-Bold.woff2') format('woff2'),
      url('../assets/fonts/Font-Files/LucidaSansStd-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LucidaSansStd-BoldItalic';
  src: url('../assets/fonts/Font-Files/LucidaSansStd-BoldItalic.woff2') format('woff2'),
      url('../assets/fonts/Font-Files/LucidaSansStd-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}


















.bigDiv {
  height: 15vh;
  width: 100%;
  background: #fff;
}
.logo {
    z-index: 111;
}